import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ApiRequestService } from '@app/core/auth/services/api-request.service';
import { APIs } from '@app/core/configs/apis.config';
import { ConfirmationService, MessageService } from 'primeng/api';
import { catchError, map, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContentEditorService {
  constructor(
    public confirmationService: ConfirmationService,
    public messageService: MessageService,
  ) {

  }
  // data service
  apiService = inject(ApiRequestService);
  editItem(obj: any) {
    return this.apiService.put(APIs.Utilities.editItem, obj).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  addItem(obj: any) {
    return this.apiService.post(APIs.Utilities.addItem, obj).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  deleteItem(id: any) {
    return this.apiService.delete(APIs.Utilities.deleteItem +'/' +id).pipe(
      map((res) => {
        return res;
      }),
    );
  }
  uploadImage(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file); // 'image' is the key expected by your backend

    return this.apiService
      .post(APIs.UploadAttachment.UploadToBlob, formData)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    // Handle error appropriately
    console.error('An error occurred:', error);
    return throwError(
      'Something went wrong with the upload; please try again later.',
    );
  }

  // Popups
  openModal(event: Event, header: string, message: string, methods: {
    accept: () => void;
    reject: () => void;
  },
  labels: {success: string, error: string}
) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: message,
      header: header,
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'p-button-text',
      acceptLabel: labels.success,
      rejectLabel: labels.error,
      accept: () => methods.accept(),
      reject: () => methods.reject(),
    });
  }
}
