import { inject, Injectable } from '@angular/core';
import { ContentEditorService } from '@app/shared/services/content-editor.service';
import { PageLoadingService } from '@app/shared/services/page-loading/page-loading.service';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private visibilitySubject = new BehaviorSubject<boolean>(false);
  private contentEditorService = inject(ContentEditorService);
  formType = new Subject();
  addOrEdit = new BehaviorSubject('edit');
  selectedFormData = new Subject();
  loadingService = inject(PageLoadingService);
  //TODO: data should be kept inside a data service not sidebar service
  private data: any = new Subject();
  // Observable to track visibility state
  visibility$ = this.visibilitySubject.asObservable();

  // Toggle the visibility of the sidebar
  toggleSidebar() {
    this.visibilitySubject.next(!this.visibilitySubject.value);
  }

  // Manually open the sidebar
  openSidebar() {
    this.visibilitySubject.next(true);
  }

  // Manually close the sidebar
  closeSidebar() {
    this.visibilitySubject.next(false);
  }

  getData() {
    return this.data;
  }

  setData(data: any) {
    if (data.formType == 'text') {
      this.formType.next('text');
    } else if (data.formType == 'text-editor') {
      this.formType.next('text-editor');
    } else if (data.formType == 'img') {
      this.formType.next('img');
    } else if (data.formType == 'list-item') {
      this.formType.next('list-item');
    }
    this.data.next(data);
  }

  editData(data: any) {
    this.loadingService.show();
    // TODO: subscription should be removed from service and kept inside the component itself
    this.contentEditorService.editItem(data).subscribe(
      (res) => {
        this.selectedFormData.next(data);
        this.closeSidebar();
        console.log('edited successfullly', res);
        this.loadingService.hide()
      },
      (err) => this.loadingService.hide(),
    ),
      () => {
        this.loadingService.hide();
      };
  }

  addData(data: any) {
    this.loadingService.show();
    // TODO: subscription should be removed from service and kept inside the component itself
    this.contentEditorService.addItem(data).subscribe(
      (res) => {
        this.selectedFormData.next(data);
        this.closeSidebar();
        console.log('edited successfullly', res);
        this.loadingService.hide()
      },
      (err) => this.loadingService.hide(),
    ),
      () => {
        this.loadingService.hide();
      };
  }
}
